//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '../../style/variables';

@viewport-height: 100dvh;

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: @text-color;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: @text-color;
}

#admin-account-email {
  //text-align: right;
  width: 100%;
  color: #f74555;
}

.primary-color {
  color: @primary-color;
}

.text-color {
  color: @text-color;
}

/* override antd styles*/
.ant-drawer-header {
  background: transparent !important;
  color: #000;
}

.ant-modal-content {
  border: 0.5px solid rgba(10, 37, 64, 0.1);
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

#ioni-main-layout {
  background: transparent;
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-top: -1px;
    }
  }

  .ant-layout {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(75px);
    max-height: 100vh;

    @supports (max-height: 100dvh) {
      max-height: 100dvh;
    }

    .ant-layout-header {
      height: auto;
    }
  }

  .ant-layout-header {
    padding: 0;
    background: transparent;
    line-height: 0;

    .header {
      display: flex;
      justify-content: space-between;
      padding-inline: 20px;
      padding-top: 20px;

      @media (min-width: 992px) {
        flex-direction: column;
      }
    }
  }
}

.integrate-data-tooltip {
  max-width: 152px !important;

  button {
    margin: 6px 0;
  }
}

.ant-layout-sider-children {
  position: relative;
}

.right-header-item {
  margin-left: auto;
}

.center-item {
  display: grid;
  place-items: center;
}

.left-header-item {
  display: flex;
  justify-content: flex-start;
}

.onboarding-modal-under {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.onboarding-modal {
  z-index: 3;
  position: absolute;
  width: 50vw;
  height: 300px;
  border-radius: 20px;
  margin: auto;
  cursor: context-menu;
  background-color: lightgray;
  top: calc(50% - 100px);
  left: calc(50% + 100px);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-modal-text-1 {
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.onboarding-modal-text-2 {
  font-size: 16px !important;
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}

.integration-popup-text-wrapper {
  line-height: 18px;
  padding: 0 12px;

  p {
    font-size: 14px;
    line-height: 24px;
  }
}

#test-chat-paragraph {
  padding-right: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #0a2540b2;
}

.app-menu-container,
.app-menu-container-no-shadow {
  display: none;
}

.logo-wrapper {
  position: fixed;
  top: 10px;
  left: 0;
  width: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  //gap: 80px;
  padding-inline: 18px;
  z-index: 50;
  transition: all ease .15s;
  border-radius: 16px;
  backdrop-filter: blur(15px);

  @media (max-width: 992px) {
    width: calc(70% - 50px);
    flex-direction: row-reverse;
  }
}

.closed {
  top: 14px;
  left: 14px;
}

